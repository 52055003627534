if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initialize);
} else {
  initialize();
}

var unloads = [];

function initialize() {
  var popupIniters = document.querySelectorAll('.popup_data_identifier');
  popupIniters.forEach((item, i) => {
    item.addEventListener('click', openPopup);
    unloads.push({ event: 'click', func: openPopup, elem: item });
  });

  unloads.push({ event: 'beforeunload', func: unload, elem: window });
  window.addEventListener('beforeunload', unload);
}

function unload() {
  /*
   * Multiple listeners has been created. Remove them when site closes.
   */
  unloads.forEach((item, i) => {
    console.log('unloading:', item.event);
    item.elem.removeEventListener(item.event, item.func);
  });
}

function openPopup(e) {
  e.preventDefault();
  if (e.target.dataset.popupText) {
    // create a new div element
    const outerContainer = document.createElement('div');
    outerContainer.classList.add(
      'fixed',
      'top-0',
      'left-0',
      'right-0',
      'bottom-0',
      'bg-primary-opacity-50',
      'flex',
      'justify-center',
      'items-center',
      'z-10',
      'popup-outer-container',
      'font-primary',
      'leading-loose'
    );
    outerContainer.addEventListener('click', close);
    unloads.push({ event: 'click', func: close, elem: outerContainer });

    // and give it some content
    const innerContainer = document.createElement('div');
    innerContainer.classList.add(
      'w-full',
      'max-h-screen',
      'max-w-md',
      'bg-primary-300',
      'p-6',
      'shadow-lg',
      'popup-inner-container',
      'relative',
      'rounded',
      'flex',
      'flex-col'
    );

    const text = document.createTextNode(e.target.dataset.popupText);
    const closeBtn = document.createElement('button');
    closeBtn.classList.add(
      'border',
      'border-black',
      'rounded-full',
      'h-10',
      'w-10',
      'close-popup-button',
      'self-end',
      'flex',
      'justify-center',
      'items-center'
    );
    closeBtn.innerHTML =
      '<svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.22 12.04"><defs><style>.cls-1{fill:none;stroke-linecap:round;stroke-width:1.5px;}</style></defs><path class="cls-1 stroke-current" d="M11.47,11.29.93.75M.75,11.29,11.29.75"/></svg>';

    // add the text node to the newly created div
    innerContainer.appendChild(closeBtn);
    innerContainer.appendChild(text);
    outerContainer.appendChild(innerContainer);

    document.body.appendChild(outerContainer);
    setTimeout(function () {
      outerContainer.classList.add('open');
    }, 10);
  }

  function close(e) {
    if (
      e.target.closest('.close-popup-button') ||
      !e.target.closest('.popup-inner-container')
    ) {
      const popup = document.querySelector('.popup-outer-container');
      if (popup) {
        // close it
        popup.classList.remove('open');
        setTimeout(function () {
          popup.remove();
        }, 300);
      }
    }
  }
}
