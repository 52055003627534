import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/respimg/ls.respimg";
import "./components/popup";

import "../css/app.pcss";

import { gtmTracking } from "./utils/gtmTracking";

if (!("object-fit" in document.createElement("a").style)) {
  require("lazysizes/plugins/object-fit/ls.object-fit");
}

Element.prototype.hasClass = function (className) {
  return (
    this.className &&
    new RegExp("(^|\\s)" + className + "(\\s|$)").test(this.className)
  );
};

const hasDark = document.body.hasClass("dark");
const scrollOffset = hasDark ? 0.05 : 0.75;

window.addEventListener("scroll", function (evt) {
  if (window.pageYOffset > window.innerHeight / 5) {
    if (document.getElementById("scrollHint")) {
      document.getElementById("scrollHint").classList.add("fade-out");
    }
  }

  if (window.pageYOffset > window.innerHeight * scrollOffset) {
    // document.getElementById("VHlogoHolder").classList.add("hidden");
    if (document.getElementById("main-nav-links-container")) {
      document
        .getElementById("main-nav-links-container")
        .classList.add("hidden");
    }

    // if(document.getElementById('productCta')){
    // 	document.getElementById('productCta').classList.add('sticky');
    // }
    if (!hasDark) {
      document.body.classList.add("dark");
    }
  } else {
    // document.getElementById("VHlogoHolder").classList.remove("hidden");
    if (document.getElementById("main-nav-links-container")) {
      document
        .getElementById("main-nav-links-container")
        .classList.remove("hidden");
    }

    // if(document.getElementById('productCta')){
    // 	document.getElementById('productCta').classList.remove('sticky');
    // }
    if (!hasDark) document.body.classList.remove("dark");
  }
});

// Global
// const toggleDropDown = document.getElementById('toggleDropDown');
// const closeDropDown = document.getElementById("closeDropDown");
// const dropDownMenu = document.querySelector("#dropDownMenu");

// toggleDropDown.addEventListener('click', function (evt) {
// 	// if (!dropDownMenu.classList.contains('visible')) {
// 	if (!dropDownMenu.hasClass('visible')) {
// 		dropDownMenu.classList.add('visible');
// 	} else {
// 		dropDownMenu.classList.remove('visible');
// 	}
// });

// closeDropDown.addEventListener("click", function (evt) {
//   dropDownMenu.classList.remove("visible");
// });

const closeStickyHeader = document.getElementById("closeStickyHeader");
const stickyHeader = document.getElementById("stickyHeader");

if (closeStickyHeader) {
  closeStickyHeader.addEventListener("click", function (evt) {
    stickyHeader.classList.add("closed");
  });
}

// Article categories
const articleCategories = document.querySelector(".article-categories");
if (articleCategories) {
  articleCategories.addEventListener("change", (event) => {
    window.location = event.target.value;
  });
}

// Add class do parent for lazyloaded images
document.addEventListener("lazyloaded", function (event) {
  const $target = event.target;
  const $parent = $target.parentNode;

  $parent.classList.add("loaded");
});

// Check elements to create sizes for srcset
window.onload = window.lazySizes.autoSizer.checkElems;

document.addEventListener("DOMContentLoaded", function () {
  initGTMTracking();

  let videosArray = document.querySelectorAll(
    '.text-module iframe[src^="https://player.vimeo.com"], .text-module iframe[src^="//player.vimeo.com"], .text-module iframe[src^="https://www.youtube.com/embed/"], .text-module iframe[src^="//www.youtube.com/embed/"]'
  );
  videosArray = Array.prototype.slice.call(videosArray);

  // let fluidElement = document.querySelector('.text-module .content');

  videosArray.map((elem) => {
    let videoHeight =
      elem.height !== "" ? elem.height : elem.style.height.replace("px", "");
    let videoWidth =
      elem.width !== "" ? elem.width : elem.style.width.replace("px", "");

    elem.dataset.aspectRatio = videoHeight / videoWidth;
    elem.removeAttribute("height");
    elem.removeAttribute("width");
    elem.removeAttribute("style");
  });

  function resizeVideo() {
    videosArray.map((elem) => {
      if (elem.parentNode) {
        let newWidth = elem.parentNode.offsetWidth;
        elem.width = newWidth;
        elem.height = newWidth * elem.dataset.aspectRatio;
      }
    });
  }

  window.addEventListener("resize", function (evt) {
    resizeVideo();
  });

  resizeVideo();

  // DEPRECATED in favor of classes to be able to have multiple buttons with internal link
  // we leave it here in case there is any elments still using ID
  let anchorLinkButton = document.getElementById("anchorLinkButton");
  if (anchorLinkButton) {
    anchorLinkButton.addEventListener("click", function (evt) {
      evt.preventDefault();

      scrollToElement(evt.currentTarget.dataset.id);
    });
  }

  let anchorLinkButtons = document.getElementsByClassName("anchorLinkButton");
  anchorLinkButtons = Array.prototype.slice.call(anchorLinkButtons);

  if (anchorLinkButtons) {
    anchorLinkButtons.map((button) => {
      button.addEventListener("click", function (evt) {
        evt.preventDefault();

        scrollToElement(evt.currentTarget.dataset.id);
      });
    });
  }

  let productCtaContainer = document.getElementById("productCtaContainer");
  let productCtaButton = document.getElementById("productCta");

  if (productCtaButton) {
    productCtaButton.addEventListener("click", function (evt) {
      evt.preventDefault();

      scrollToElement(evt.currentTarget.dataset.id);

      productCtaContainer.style.display = "none";
      // productCtaButton.removeEventListener('click');
    });
  }
});

function scrollToElement(elemId) {
  document.getElementById(elemId).scrollIntoView({
    behavior: "smooth",
  });
}

if (module.hot) {
  module.hot.accept();
}

// GTM tracking

function initGTMTracking() {
  let trackingElements = document.querySelectorAll("[data-tracking]");
  trackingElements = Array.prototype.slice.call(trackingElements);

  let mailtoLinks = document.querySelectorAll('a[href^="mailto:"]');
  mailtoLinks = Array.prototype.slice.call(mailtoLinks);

  trackingElements.map((elem) => {
    elem.addEventListener("click", function (evt) {
      let trackingProps = {};

      let trackingObj = JSON.parse(evt.currentTarget.dataset.tracking);
      for (let [key, value] of Object.entries(trackingObj)) {
        if (key !== "pageUrl") {
          trackingProps[key] = decodeURIComponent(value);
        } else {
          trackingProps[key] = decodeURI(value);
        }
      }

      gtmTracking(trackingProps);
    });
  });

  mailtoLinks.map((elem) => {
    elem.addEventListener("click", function (evt) {
      let trackingProps = {
        event: "epost_clicked",
        type: "Click",
        epost: evt.target.pathname,
        pageUrl: window.location.pathname,
      };

      gtmTracking(trackingProps);
    });
  });
}
